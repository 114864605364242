import { Menu as AntdMenu } from 'antd'
import { HomeOutlined, SettingOutlined, UserOutlined, BookOutlined, ProfileOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Icon from '../icon';
import { Children } from 'react';

interface MenuProps {
    current?: string
}

const Menu = ({ current = 'home' }: MenuProps) => {

    const nav = useNavigate();

    const onMenuClick = (item: any) => {
        nav('/' + item.key);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
            <AntdMenu
                style={{ flex: "0 0 auto" }}
                mode="inline"
                defaultSelectedKeys={[current === '/' || '' ? 'document' : current]}
                onClick={(item: any) => onMenuClick(item)}
                items={[
                    {
                        label: "实时监测",
                        key: "monitor",
                        icon: <Icon type='icon-jiance' />
                    },
                    {
                        label: "患者管理",
                        key: "document",
                        icon: <HomeOutlined />
                    },
                    {
                        label: "患者数据",
                        key: "history",
                        icon: <ProfileOutlined />
                    },
                ]}
            />

            <AntdMenu
                style={{ position: 'fixed', bottom: '0', width: '200px' }}
                mode="inline"
                defaultSelectedKeys={[current]}
                onClick={(item: any) => onMenuClick(item)}
                items={[
                    {
                        label: "账户管理",
                        key: "account",
                        icon: <UserOutlined />
                    },
                    {
                        label: "设置",
                        key: "settings",
                        icon: <SettingOutlined />,
                        children: [
                            {
                                label: "基本设置",
                                key: "setting",
                                icon: <UserOutlined />
                            },
                            {
                                label: "护理级别设置",
                                key: "care-level",
                                icon: <UserOutlined />
                            },
                            {
                                label: "科室管理",
                                key: "department",
                                icon: <UserOutlined />
                            }
                        ]
                    },
                ]}
            />
        </div>
    )
}

export default Menu
