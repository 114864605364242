import './setting.less'
import { Button, Form, Input, message } from "antd"
import { useEffect, useState } from "react"
import { useForm } from "antd/es/form/Form"
import { useSelector } from "react-redux"
import { IState } from "../../redux"
import { getSettings, settingsSave } from '../../api'

export interface saveDataType {
    high: string[],
    low: string[],
    warningDelayMinutes: number,
    cutForMinutes: number,
    //隐藏报警时间
    hideForMinutes: number
}

const Setting = () => {

    const [form] = useForm<saveDataType>();

    useEffect(() => {
        //设置获取
        getSettings().then(
            res => {
                form.setFieldsValue(res.data.data);
            }
        )
    }, []);

    //提交
    const onFinish = (v: saveDataType) => {
        form.validateFields().then(
            values => {
                settingsSave({
                    ...values,
                    hideForMinutes: Number(v.hideForMinutes),
                    warningDelayMinutes: Number(v.warningDelayMinutes),
                    cutForMinutes: Number(v.cutForMinutes)
                }).then(
                    () => {
                        message.success('设置修改成功')
                    }
                )
            }
        )
    }

    return (
        <div className='set_up_box'>
            <div className='set_up_inner'>
                <Form
                    form={form}
                    onFinish={onFinish}>
                    <Form.Item
                        name="high"
                        label="高温报警设置："
                        rules={[
                            { required: true, message: '报警高温列表不能为空' },
                            // { validator: GenTempValidator(1, 50) }
                        ]}
                    >
                        <InputTemperature title='温度' />
                    </Form.Item>
                    <Form.Item
                        name="low"
                        label="低温报警设置："
                        rules={[
                            { required: true, message: '报警低温列表不能为空' },
                            // { validator: GenTempValidator(1, 50) }
                        ]}
                    >
                        <InputTemperature title='温度' />
                    </Form.Item>
                    <Form.Item
                        label='报警延迟(min)'
                        name="warningDelayMinutes"
                        rules={[{ required: true, message: '报警延迟' }]}
                    >
                        <Input min={1} style={{ textAlign: 'center' }} type='number' />
                    </Form.Item>
                    <Form.Item
                        label='自动清空异常设备时间(min)'
                        name="cutForMinutes"
                        rules={[{ required: true, message: '断开时间不能为空' }]}
                    >
                        <Input min={1} style={{ textAlign: 'center' }} type='number' />
                    </Form.Item>
                    <Form.Item
                        label='隐藏报警时间(min)'
                        name="hideForMinutes"
                        rules={[{ required: true, message: '隐藏报警时间不能为空' }]}
                    >
                        <Input min='1' style={{ textAlign: 'center' }} type='number' />
                    </Form.Item>
                    <Form.Item
                        label="费用（元/时）"
                        name="fee"
                        rules={[
                            {required: true, message: '每小时费用不能为空'},
                            {validator: (_, value) => {
                                if (isNaN(Number(value))) {
                                    return Promise.reject(new Error("请输入正确的金额"))
                                } else {
                                    return Promise.resolve()
                                }
                            }}
                        ]}
                    >
                        <Input style={{textAlign:'center'}} />
                    </Form.Item>
                    <Form.Item
                        label="自动开始测量温度（℃）"
                        name="autoStartTemp"
                        rules={[
                            {required: true, message: '每小时费用不能为空'},
                            {validator: (_, value) => {
                                    if (isNaN(Number(value))) {
                                        return Promise.reject(new Error("请输入正确的金额"))
                                    } else {
                                        return Promise.resolve()
                                    }
                                }}
                        ]}
                    >
                        <Input style={{textAlign:'center'}} />
                    </Form.Item>
                    <Form.Item
                        label="自动结束测量温度（℃）"
                        name="autoEndTemp"
                        rules={[
                            {required: true, message: '每小时费用不能为空'},
                            {validator: (_, value) => {
                                    if (isNaN(Number(value))) {
                                        return Promise.reject(new Error("请输入正确的金额"))
                                    } else {
                                        return Promise.resolve()
                                    }
                                }}
                        ]}
                    >
                        <Input style={{textAlign:'center'}} />
                    </Form.Item>
                    <Button type="primary" htmlType="submit">保存</Button>
                </Form>
            </div>
        </div>
    )
};

export default Setting;

interface InputTemperatureProps {
    title: string,
    value?: string[],
    onChange?: (v: string[]) => void
}

const InputTemperature = ({ title, value, onChange }: InputTemperatureProps) => {
    //本地value
    const [values, setValues] = useState<Array<string>>([]);

    useEffect(() => {
        if (value) {
            //获取变化的value
            setValues(value || []);
        }
    }, [value]);

    //value在change变化存储
    const onMyChange = () => {
        if (onChange) {
            // let v: any[] = values.map(item => Number(item));
            //将value转换为number类型
            onChange(values);
        }
    }

    const onInputChange = (v: string, index: number) => {
        if (isNaN(Number(v))) {
            values[index] = ''
        } else {
            //改变本地value
            values[index] = v;
        }

        //保存
        setValues(values.slice())
        onMyChange();
    }
    const user = useSelector((state: IState) => state.userReducer);

    //删除点击
    const onReduceClick = (index: number) => {
        console.log(user);
        //删除
        values.splice(index, 1);
        //替换
        setValues(values.slice());
        onMyChange();
    };

    //增加报警温度
    const onAddClick = () => {
        setValues(v => v.concat(''))
    }

    return (
        <div className='input_temperature_box'>
            <div className='input_temperature_inner'>
                {
                    values?.map((item, index) => {
                        return (
                            <div className='input_temperature_main' key={index}>
                                <h4>{title}{index + 1}：</h4>
                                <Input style={{ textAlign: 'center' }} placeholder={`请输入${title}温度`}
                                    value={item ? item : ''}
                                    onChange={(e: any) => onInputChange(e.target.value, index)} />
                                <span>℃</span>
                                <div className='input_temperature_reduce' onClick={() => onReduceClick(index)}>-</div>
                            </div>
                        )
                    }, [])
                }
            </div>
            <div className='input_temperature_add' onClick={onAddClick}>+</div>
        </div>
    )
}