import {ExclamationCircleOutlined, EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons"
import {Alert, Button, Form, Input, message, Modal, Select, Space, Spin} from "antd"
import {useForm} from "antd/lib/form/Form"
import Table, {ColumnsType} from "antd/lib/table"
import {AxiosPromise} from "axios"
import moment from "moment"
import {ChangeEvent, useCallback, useEffect, useRef, useState, FocusEvent} from "react"
import {useSelector} from "react-redux"
import {
    bindDocument,
    createDocument,
    deleteDocument,
    forceUnbindDocument,
    listCareLevel,
    listDepartment,
    listDocument,
    listFee, unbindDevice,
    unbindDocument,
    updateDocument
} from "../../api"
import {IState} from "../../redux"
import document from "./index";

interface DataType {
    id: string
    name: string
    patientCode: string
    mac: string
    deptId: string
}

let timeoutId

const Document = () => {
    const [data, setData] = useState<Array<DataType>>([])
    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [showEditor, setShowEditor] = useState<boolean>(false)
    const [form] = useForm()
    const [bindForm] = useForm()
    const [currentId, setCurrentId] = useState<string>("")
    const [size, setSize] = useState<number>(15)
    const [showBind, setShowBind] = useState<boolean>(false)
    const [levels, setLevels] = useState<Array<any>>([])
    const [keyword, setKeyword] = useState("")
    const [deptId, setDeptId] = useState("")

    const [feeList, setFeeList] = useState<Array<DataType>>([])
    const [feePage, setFeePage] = useState<number>(1)
    const [feeTotal, setFeeTotal] = useState<number>(0)
    const [feeSize, setFeeSize] = useState<number>(15)
    const [currentDocId, setCurrentDocId] = useState("")

    const selfDeptId = useSelector((state: IState) => state.userReducer.deptId)
    const isMaster = useSelector((state: IState) => state.userReducer.isMaster)
    const [bindMac, setBindMac] = useState<string>('')

    const [depts, setDepts] = useState<Array<any>>([])
    const [showUnBind,setShowUnBind] = useState<boolean>(false);
    const [unBindMac,setUnBindMac] = useState<string>("");
    const [unBindMacData,setUnBindMacData] = useState<Array<string>>([]);
    const feeColumns: ColumnsType<DataType> = [
        {
            title: "开始时间",
            dataIndex: "start",
            align: "center",
            render: (start: number) => {
                return moment(start * 1000).format("YYYY-MM-DD HH:mm:ss")
            }
        },
        {
            title: '结束时间',
            dataIndex: 'end',
            align: 'center',
            render: (end: number) => {
                return moment(end * 1000).format("YYYY-MM-DD HH:mm:ss")
            }
        },
        {
            title: '时长',
            align: "center",
            render: (_, record: any) => {
                let d = moment.duration(record.end - record.start, 'seconds')
                return `${d.hours()}小时${d.minutes()}分${d.seconds()}秒`
            }
        },
        {
            title: '单价',
            dataIndex: 'price',
            align: 'center',
            render: (price: string) => {
                return price && price !== "0" ? price + "元/时" : "-"
            }
        },
        {
            title: '总价',
            dataIndex: 'amount',
            align: 'center',
            render: (amount: string) => {
                return amount && amount !== "0" ? amount + "元" : "-"
            }
        }
    ];

    const columns: ColumnsType<DataType> = [
        {
            title: "医疗卡号",
            dataIndex: "patientCode",
            align: "center",
        },
        {
            title: '名称',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: '科室',
            dataIndex: 'deptName',
            align: 'center',
        },
        {
            title: '护理级别',
            dataIndex: 'careLevelId',
            align: 'center',
            render: (careLevelId: string) => {
                return levels.find(item => item.id == careLevelId)?.name
            }
        },
        {
            title: '设备mac',
            dataIndex: 'mac',
            align: 'center'
        },
        {
            title: "床号",
            dataIndex: 'address',
            align: 'center'
        },
        {
            title: '操作',
            render: (_, record) => (
                <Space size="middle">
                    {/*<Button onClick={() => edit(record)}>编辑</Button>*/}
                    {/*{*/}
                    {/*    selfDeptId === record.deptId ? <Button type="primary" danger onClick={() => del(record)}>删除</Button> : null*/}
                    {/*}*/}
                    {
                        selfDeptId === record.deptId ? <div>
                            <Button onClick={() => edit(record)} style={{marginRight: '15px'}}>编辑</Button>
                            <Button type="primary" danger onClick={() => del(record)}
                                    style={{marginRight: '15px'}}>删除</Button>
                            {
                                record.mac ?
                                    <Button onClick={() => unbind(record.id, record.mac, record.name)}
                                            danger>解绑设备</Button> :
                                    <Button type="primary" onClick={() => {
                                        showBindModal(record)
                                    }}>绑定设备</Button>
                            }
                        </div> : null
                    }
                    {/*{*/}
                    {/*    record.mac ?*/}
                    {/*        <Button onClick={() => unbind(record.id, record.mac, record.name)} danger>解绑设备</Button> :*/}
                    {/*        <Button type="primary" onClick={() => showBindModal(record)}>绑定设备</Button>*/}
                    {/*}*/}
                    <Button type="ghost" onClick={() => setCurrentDocId(record.id)}>费用列表</Button>
                </Space>
            ),
        },
    ];

    const edit = (record: DataType) => {
        setCurrentId(record.id)
        form.setFieldsValue(record)
        setShowEditor(true)
    }

    const submit = () => {
        form.validateFields().then(
            values => {
                let request: AxiosPromise
                if (currentId) {
                    request = updateDocument(currentId, values)
                } else {
                    request = createDocument(values)
                }
                request.then(
                    () => {
                        getData()
                        message.success("保存成功")
                        setCurrentId("")
                        setShowEditor(false)
                        form.resetFields()
                    },
                    err => {
                        getData()
                        message.error(err.data.msg)
                    }
                )
            }
        )
    }

    const del = (record: DataType) => {
        Modal.confirm({
            title: '是否删除?',
            icon: <ExclamationCircleOutlined/>,
            content: `姓名: ${record.name}`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                deleteDocument(record.id).then(
                    () => {
                        if (data.length < 2 && page >= 2) {
                            setPage(page - 1)
                        } else {
                            getData()
                        }
                        message.success("删除成功")
                    }
                )
            }
        })
    }

    const getData = useCallback(() => {
        listDocument(page, size, keyword, deptId).then(
            res => {
                setData(res.data.data)
                setTotal(res.data.meta.total)
            }
        )
    }, [page, keyword, deptId])

    const showBindModal = (record: any) => {
        setCurrentId(record.id)
        setShowBind(true)
    }

    const bind = () => {
        setBindMac("")
        bindForm.validateFields().then(
            values => {
                bindDocument(currentId, bindMac, values.address).then(
                    () => {
                        getData()
                        message.success("绑定成功")
                        setCurrentId("")
                        setShowBind(false)
                        bindForm.resetFields()
                    },
                    err => {
                        getData()
                        if (err.data.code === 409) {
                            Modal.confirm({
                                title: '设备已被绑定，是否重新绑定设备到新患者?',
                                icon: <ExclamationCircleOutlined/>,
                                content: `科室: ${err.data.data.deptName}, 姓名: ${err.data.data.name}, 设备地址: ${err.data.data.mac}`,
                                okText: '确认',
                                cancelText: '取消',
                                onOk: () => {
                                    forceUnbindDocument(err.data.data.mac).then(
                                        () => {
                                            bindDocument(currentId, values.mac, values.address).then(
                                                () => {
                                                    getData()
                                                    message.success("绑定成功")
                                                    setCurrentId("")
                                                    setShowBind(false)
                                                    bindForm.resetFields()
                                                },
                                                err => {
                                                    getData()
                                                    message.error(err.data.msg)
                                                }
                                            )
                                        },
                                        err => {
                                            getData()
                                            message.error(err.data.msg)
                                        }
                                    )
                                }
                            });
                        } else {
                            message.error(err.data.msg)
                        }
                    }
                )
            }
        )
    }

    const unbind = (id: string, mac: string, name: string) => {
        Modal.confirm({
            title: '是否确认解绑设备',
            icon: <ExclamationCircleOutlined/>,
            content: `姓名: ${name}, 设备地址: ${mac}`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                unbindDocument(id).then(
                    () => {
                        getData()
                        message.success("解绑成功")
                    },
                    err => {
                        getData()
                        message.error(err.data.msg)
                    }
                )
            }
        });
    }

    const keywordChange = (e: ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timeoutId)
        let value = e.currentTarget.value
        timeoutId = setTimeout(() => {
            setKeyword(value)
        }, 500)
    }

    const deptIdChange = (value: any) => {
        setDeptId(value)
    }

    const getFeeList = useCallback(() => {
        if (currentDocId) {
            listFee(currentDocId, feePage, feeSize).then(
                res => {
                    setFeeList(res.data.data)
                    setFeeTotal(res.data.meta.total)
                }
            )
        }
    }, [feePage, currentDocId])

    useEffect(() => {
        listCareLevel().then(
            res => {
                setLevels(res.data.data)
            }
        )
        listDepartment(1, 100).then(
            res => {
                setDepts(res.data.data)
            }
        )
    }, [])

    useEffect(() => {
        getData()
    }, [page, keyword, deptId])

    useEffect(() => {
        getFeeList()
    }, [currentDocId, feePage])

    const unBind = ()=>{

    }

    const inputBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (bindMac) {
            const input: HTMLInputElement = e.target
            input.focus();
        }
    }

    const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const regex = /[\s\S]*(([A-Fa-f0-9]{2}:){5}[A-Fa-f0-9]{2})[\s\S]*/;
        const v = e.target.value;
        if (v.length >= 17) {
            let regData: Array<string>|null = v.match(regex);
            if (regData && regData.length > 1) {
                let mac = regData[1]
                setUnBindMac(mac);
                unbindDevice(mac).then((res:any)=>{
                    setUnBindMacData([
                        ...unBindMacData,
                        mac
                    ])
                    setUnBindMac("");
                    e.target.value = "";
                    getData()
                    message.success(`mac地址为${mac}的设备解绑成功！`)
                },(err:any)=>{
                    setUnBindMac("")
                    e.target.value = ""
                    if(err.data.code>=400){
                        return  message.error(err.data.msg)
                    }
                })
            }
        }
    }

    return (
        <>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{flex: 1}}>
                    <Button type="primary" onClick={() => setShowEditor(true)}>创建</Button>
                    <Button type="primary" onClick={()=>setShowUnBind(true)}
                            style={{marginLeft: "20px"}}>批量解绑</Button>
                </div>
                <div style={{display: "flex", flex: 3, justifyContent: "flex-end", margin: "10px 0 10px 0"}}>
                    <Input onChange={keywordChange} style={{width: "200px"}} placeholder="按医疗卡或姓名搜索"
                           allowClear/>&nbsp;&nbsp;
                    {
                        isMaster ? (
                            <Select style={{width: "200px"}} placeholder="按科室搜索" allowClear onChange={deptIdChange}>
                                {
                                    depts.map((item: any) => (
                                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))
                                }
                            </Select>
                        ) : null
                    }

                </div>
            </div>
            <Table
                rowKey="instanceId"
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: size,
                    current: page,
                    total: total,
                    onChange: (page, size) => {
                        setPage(page);
                        setSize(size)
                    },
                }}
            />
            <Modal title="患者" visible={showEditor} onOk={submit} onCancel={() => {
                setCurrentId("");
                setShowEditor(false);
                form.resetFields()
            }}>
                <Form form={form}>
                    <Form.Item
                        label="医疗卡号"
                        name="patientCode"
                        rules={[{required: true, message: "医疗卡号不能为空"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="名称"
                        name="name"
                        rules={[{required: true, message: "名称不能为空"}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="护理级别"
                        name="careLevelId"
                        rules={[{required: true, message: "护理级别不能为空"}]}
                    >
                        <Select>
                            {
                                levels.map(item => (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal focusTriggerAfterClose={false} destroyOnClose={true} title="绑定设备" visible={showBind} onOk={bind}
                   onCancel={() => {
                       setCurrentId("");
                       setShowBind(false);
                       setBindMac('')
                       bindForm.resetFields()
                   }}>
                <Form form={bindForm}>
                    {/*<Form.Item*/}
                    {/*    label="设备mac地址"*/}
                    {/*    name="mac"*/}
                    {/*    rules={[{required: true, message: "mac地址不能为空"}]}*/}
                    {/*>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                    <div>
                        <span style={{fontSize:"12px",color:"red",display:"block",textAlign:'center'}}>请先扫描设备二维码后，再输入当前床号信息。<br/></span>
                        <span><span style={{color:"#ff4d4f",fontSize:"14px",fontFamily:"SimSun, sans-serif",lineHeight:1,marginRight:"3px"}}>*</span>当前mac地址：{bindMac ? bindMac : '未扫描'}</span>
                        {
                            bindMac?(
                                <Button type="primary" style={{marginLeft:"10px"}} onClick={()=>{
                                    setBindMac("")
                                    const inputEle:HTMLElement | null = window.document.getElementById("device-mac");
                                    inputEle?.focus()
                                }}>重扫</Button>
                            ):null
                        }
                    </div>
                    <input id="device-mac" onBlur={(e) => {
                        console.log(e)
                        if (bindMac.length < 17) {
                            const input: HTMLInputElement = e.target
                            input.focus();
                        }
                    }} autoFocus={true} type="password" onChange={(e: any) => {
                        const regex = /[\s\S]*(([A-Fa-f0-9]{2}:){5}[A-Fa-f0-9]{2})[\s\S]*/;
                        const v = e.target.value;
                        if (v.length >= 17) {
                            let mac: Array<string> = v.match(regex);
                            if (Array.isArray(mac) && mac.length >= 1) {
                                setBindMac(mac[1])
                            }
                        }
                    }} style={{height: '0', border: "0", padding: 0, margin: 0}}/>
                    <Form.Item
                        label="当前床号"
                        name="address"
                        rules={[{required: true, message: "当前床号不能为空"}]}
                    >
                        <Input id="bed-num"/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="费用列表"
                visible={!!currentDocId}
                onOk={() => {
                    setCurrentDocId("");

                    setFeeList([]);
                }}
                onCancel={() => {
                    setCurrentDocId("");
                    setFeeList([]);
                }}
                closable={false}
            >
                <p>
                    <Table
                        rowKey="id"
                        columns={feeColumns}
                        dataSource={feeList}
                        pagination={{
                            pageSize: feeSize,
                            current: feePage,
                            total: feeTotal,
                            onChange: (page, size) => {
                                setFeePage(page);
                                setFeeSize(size)
                            },
                        }}
                    />
                </p>
            </Modal>
            <Modal focusTriggerAfterClose={false} destroyOnClose={true} title="解除绑定设备" onCancel={()=>{
                setShowUnBind(false);
                setUnBindMacData([]);
                getData()
            }} visible={showUnBind} onOk={unBind} footer={null}>
                <div style={{color:"red",fontSize:"12px",textAlign:"center"}}>请先扫描二维码</div>
                {
                    unBindMacData.length>0?unBindMacData.map((item:string,index:number)=>
                            <div key={index}>
                                mac地址为{item}的设备解绑成功
                            </div>
                    )
                    :null
                }

                <input
                    onBlur={inputBlur}
                    autoFocus={true}
                    type="password"
                    onChange={inputChange}
                    style={{height: '0', border: "0", padding: 0, margin: 0}}
                />
            </Modal>
        </>
    )
}

export default Document
