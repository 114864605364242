import { CSSProperties, useCallback, useEffect, useMemo, useState } from "react"
import * as echarts from "echarts"
import "./index.less"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { Button, message } from "antd"
import {start as startDevice, end as endDevice} from "../../api";
import electricity from '../../assets/img/electricity.png';
import lowElectricity from  '../../assets/img/low-electricity.png';

interface IProps {
    id: string
    address: string
    name: string
    startTime: number
    temps: Array<{
        temp: string
        time: number
    }>
    warn: {
        type: number
        level: string
    },
    style?: CSSProperties
    currentTemp: string
    patientCode: string
    start: boolean
    mac: string
    instanceId: string
    deptName: string
    //添加电量（吕）
    battery:number
}

interface theme {
    fontColor: string
    backgroundColor: string
}

const normal: theme = {
    fontColor: "#00B227",
    backgroundColor: "#D2FFDC"
}
const low: theme = {
    fontColor: "#4186F4",
    backgroundColor: "#D3E4FF"
}
const high: theme = {
    fontColor: "#F62E2F",
    backgroundColor: "#FFEFEF"
}

const Card = ({ id, address, name, startTime, temps = [], style, warn, currentTemp, patientCode, start, mac, instanceId, deptName,battery}: IProps) => {
    const [chart, setChart] = useState<any>(null)
    const [theme, setTheme] = useState<theme>(normal)
    const nav = useNavigate()

    const text = useMemo(() => {
        if(warn.type && warn.level) {
            switch (warn.type) {
                case -1:
                    return `低于阈值: ${warn.level}`
                case 1:
                    return `高于阈值: ${warn.level}`
                default:
                    return ''
            }
        }else {
            return ''
        }
    }, [warn])

    const goToDetail = useCallback(() => {
        nav(`/history/${instanceId}`)
    }, [instanceId])

    const toggle = (e: any) => {
        e.stopPropagation()
        if (start) {
            endDevice(mac).then(
                () => {
                    message.success("结束测量")
                }
            )
        } else {
            startDevice(mac).then(
                () => {
                    message.success("开始测量")
                }
            )
        }
    }

    useEffect(() => {
        let chart = echarts.init(
            document.getElementById(id) as HTMLElement,
        );
        setChart(chart)
    }, [])

    useEffect(() => {
        switch (warn.type) {
            case -1:
                setTheme(low)
                break
            case 0:
                setTheme(normal)
                break
            case 1:
                setTheme(high)
                break
        }
    }, [warn])

    useEffect(() => {
        if (chart && temps.length > 0) {
            chart.setOption({
                grid: {
                    top: 20,
                    left: 38,//22
                    right: 1,
                    bottom: 20,
                },
                xAxis: {
                    type: 'category',
                    data: temps.map(item => moment(item.time*1000).format("HH:mm:ss"))
                },
                yAxis: {
                    type: 'value',
                    //吕添加单位
                    axisLabel: {
                        //在y轴的数据的值旁边拼接单位
                        formatter: "{value}℃",
                    },
                    max: 45,
                    min: 30,
                    splitLine: {
                        show: false
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                series: [
                    {
                        //吕（添加曲线平滑）
                        smooth: true,
                        data: temps.map(item => item.temp),
                        type: 'line',
                        showSymbol: false,
                        itemStyle: {
                            color: theme.fontColor
                        },
                        lineStyle: {
                            color: theme.fontColor
                        },
                        areaStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                offset: 0,
                                color: theme.fontColor
                            }, {
                                offset: 1,
                                color: '#fff'
                            }]),
                        }
                    }
                ]
            });
        }
    }, [chart, temps, theme])

    return (
        <div className="card" style={style}>
            <div onClick={goToDetail} className="temp" style={{ backgroundColor: theme.backgroundColor, color: theme.fontColor, position: "relative" }}>
                <span>{currentTemp}℃</span>
                <span style={{ fontSize: "20px" }}>{text}</span>
                <Button
                    type="dashed"
                    className={start ? "control-button-pause" : "control-button-begin"}
                    size="small"
                    style={{borderColor:theme.fontColor,color:theme.fontColor}}
                    onClick={toggle}
                >{start ? "结束测量" : "开始测量"}</Button>
                {/*添加电量（吕）*/}
                <div className='lv-electricity'>
                    <div>
                        <img src={Number(battery)>10?electricity:lowElectricity} alt=""/>
                        {/*<img src={Number(battery)>10?electricity:''} alt=""/>*/}
                    </div>
                    <span style={{color:Number(battery)>10?'#000':"#F62E2F"}}>{battery}%</span>
                </div>
            </div>
            <div onClick={goToDetail} className="info">
                <div className="name">{name}</div>
                <div className="address">{deptName}:&nbsp;{address}</div>
            </div>
            <div id={id} className="chart">图表</div>
        </div>
    )
}

export default Card