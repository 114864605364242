import { Modal } from "antd";
import { useCallback, useEffect, useState } from "react";

interface PwdTipProps {
    password: string
}
// 初始密码提示窗
const PwdTip = ({password}: PwdTipProps) => {
    const [pwd, setPwd] = useState<string>('')

    const ok = useCallback(() => {
        navigator.clipboard.writeText(password)
        setPwd('')
    }, [password])

    useEffect(() => {
        setPwd(password)
    }, [password])

    return (
        <Modal
            visible={!!pwd}
            onOk={ok}
            okText="复制密码"
            closable={true}
            onCancel={() => setPwd('')}
            cancelText="确定"
        >
            <p>初始密码：{password}</p>
        </Modal>
    )
}

export default PwdTip
