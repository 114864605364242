import axios from "./axios"
import Qs from 'qs'
import {number_d} from "echarts/types/dist/shared";

//登录
export const login = (data: { username: string, password: string }) => axios.post('/login', data)

//当前登录信息
export const self = () => axios.get('/self')

//--------------------------------------------------护理级别
export const listCareLevel = (page: number = 1, size: number = 15) => axios.get(`/care-levels?page=${page}&size=${size}`)
export const createCareLevel = (data: any) => axios.post('/care-levels', data)
export const updateCareLevel = (id: string, data: any) => axios.put(`/care-levels/${id}`, data)
export const deleteCareLevel = (id: string) => axios.delete(`/care-levels/${id}`)

//--------------------------------------------------设置
export const getSetting = (key: string) => axios.get(`/settings/load-by-key/${key}`)
export const getSettings = () => axios.get('/settings/load')
export const settingsSave = (data: any) => axios.put('/settings/save', data)

//---------------------------------------------------账号

export const listAccount = (page: number = 1, size: number = 15) => {
    let query = {
        page,
        size
    }
    return axios.get(`/accounts?${Qs.stringify(query, { skipNulls: true })}`)
};
export const createAccount = (data: any) => axios.post('/accounts', data);
export const updateAccount = (id: string, data: any) => axios.put(`/accounts/${id}`, data)
export const delAccount = (id: string) => axios.delete(`/accounts/${id}`)
export const resetAccountPassword = (id: string) => axios.put(`/accounts/${id}/reset-password`);

//----------------------------------------------------科室
export const listDepartment = (page: number = 1, size: number = 15) => axios.get(`/departments?page=${page}&size=${size}`)
export const createDepartment = (data: any) => axios.post('/departments', data)
export const updateDepartment = (id: string, data: any) => axios.put(`/departments/${id}`, data)
export const deleteDepartment = (id: string) => axios.delete(`/departments/${id}`)

//-----------------------------------------------------档案
export const listDocument = (page: number = 1, size: number = 15, keyword: string = "", deptId: string = "") => {
    return axios.get(`/documents?page=${page}&size=${size}${keyword ? `&filters[keyword]=${keyword}` : ''}${deptId ? `&filters[dept_id]=${deptId}` : ''}`)
}
export const createDocument = (data: any) => axios.post('/documents', data)
export const updateDocument = (id: string, data: any) => axios.put(`/documents/${id}`, data)
export const deleteDocument = (id: string) => axios.delete(`/documents/${id}`)
export const bindDocument = (id: string, mac: string, address: string) => axios.put(`documents/${id}/bind`, { mac, address })
export const unbindDocument = (id: string) => axios.put(`documents/${id}/unbind`)
export const listFee = (id: string, page: number = 1, size: number = 15) => axios.get(`documents/${id}/fee?page=${page}&size=${size}`)
export const forceUnbindDocument = (mac: string) => axios.put(`devices/${mac}/force-unbind`)


//-----------------------------------------------------实时监控
export const listDevice = () => axios.get(`devices`)
export const hideDeviceWarn = (mac: string) => axios.put(`devices/${encodeURIComponent(mac)}/hide-warn`)

//-----------------------------------------------------历史
export const listHistory = (page: number = 1, size: number = 15,keyword:string|null=null,dept_id:string|null=null) => {
    let query = {
      page,
      size,
      filters:{
          keyword,
          dept_id
      }
    };
    return axios.get(`history?${Qs.stringify(query,{skipNulls: true})}`);
}
export const documentByHistoryId = (id: string) => axios.get(`history/${id}/document`)
export const fetchTemps = (instId: string, start: number, end: number) => axios.get(`history/${instId}/temps?filters[start]=${start}&filters[end]=${end}`)
export const listMaximumTemp = (instId: string, start: number, end: number) => axios.get(`history/${instId}/maximum-list?filters[start]=${start}&filters[end]=${end}`)

//-----------------------------------------------------激活相关功能
export const actived = () => axios.get(`actived`) //是否激活
export const register = (code: string) => axios.put(`register`, {code}) //激活码激活
export const start = (mac: string) => axios.put(`devices/${mac}/start`)
export const end = (mac: string) => axios.put(`devices/${mac}/end`)

//解绑设备
export const unbindDevice = (mac:string)=>axios.put(`devices/${mac}/force-unbind`)
// //修改密码
// export const changePassword = (data: passwdType) => axios.put('/change-password', data);


// //档案列表
// export const documentList = (page: number = 1, size: number = 15, filters: any = null) => {
//     let query: any = {
//         page,
//         size
//     }
//     if (filters) {
//         query.filters = filters
//     }
//     return axios.get(`/documents?${Qs.stringify(query, {skipNulls: true})}`)
// }

// //档案详情
// export const getDocument = (patientCode: string) => {
//     return axios.get(`/documents/${patientCode}`)
// }

// //带搜索条件的温度点集合
// export const getTemps = (patientCode: string, startTime: number, endTime: number, durationSeconds: number) => {
//     let query = {
//         filters: {
//             start_time: startTime,
//             end_time: endTime,
//             duration_seconds: durationSeconds
//         }
//     }
//     return axios.get(`documents/${patientCode}/temps?${Qs.stringify(query)}`)
// }

// //带搜索条件的温度列表
// export const getTempRecords = (patientCode: string, startTime: number, endTime: number, durationSeconds: number, page: number = 1, size: number = 15) => {
//     let query = {
//         filters: {
//             start_time: startTime,
//             end_time: endTime,
//             duration_seconds: durationSeconds
//         },
//         page,
//         size,
//     }
//     return axios.get(`documents/${patientCode}/temp-records?${Qs.stringify(query)}`)
// }

// //清除异常设备
// export const abnormalDone = (patientCode: string) => {
//     return axios.put(`documents/devices/exceptions/${patientCode}/done`)
// }

// //隐藏报警
// export const hideWarn = (patientCode: string) => {
//     return axios.put(`documents/devices/${patientCode}/hide-warn`)
// }
