import { message } from "antd"
import { CSSProperties, useCallback, useEffect, useState } from "react"
import { getSetting, hideDeviceWarn } from "../../../api"
import './index.less'

interface theme {
    fontColor: string
    backgroundColor: string
}
const low: theme = {
    fontColor: "#4186F4",
    backgroundColor: "#D3E4FF"
}
const high: theme = {
    fontColor: "#F62E2F",
    backgroundColor: "#FFEFEF"
}

interface IProps {
    type: number
    level: string
    name: string
    address: string
    mac: string
    style?: CSSProperties
}

const WarnCard = ({type, level, name, address, style, mac}: IProps) => {
    const [theme, setTheme] = useState(low)
    const [text, setText] = useState('')

    const hide = useCallback(() => {
        getSetting("hide_for_minutes").then(
            res => {
                hideDeviceWarn(mac).then(
                    () => {
                        message.success(`报警将隐藏${res.data.data.value}分钟`)
                    },
                    err => {
                        message.error(err.response.data.msg)
                    }
                )
            }
        )
        
    }, [mac])

    useEffect(() => {
        switch (type) {
            case 1:
                setTheme(high)
                setText(`高于${level}℃`)
                break
            case -1:
                setTheme(low)
                setText(`低于${level}℃`)
                break
        }
    }, [type])

    return (
        <div className="warn-card" style={{...style, backgroundColor: theme.backgroundColor}}>
            <div className="close" onClick={hide}>x</div>
            <div className="info">
                <div>{name}</div>
                <div style={{fontSize: "20px"}}>{address}</div>
            </div>
            <div className="message">
                {text}
            </div>
        </div>
    )
}

export default WarnCard