import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface userState {
    id: string
    username: string
    deptName: string
    deptId: string
    isMaster: boolean
}

const initUserState: userState = {
    id: '',
    username: '',
    deptName: '',
    deptId: '',
    isMaster: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState: initUserState,
    reducers: {
        setUser: (state: userState, action: PayloadAction<userState>): userState => {
            return action.payload
        }
    }
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
