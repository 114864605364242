import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { DeviceSaga } from '../saga'
import userReducer from './reducers/user'
import deviceReducer from './reducers/device'


export const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: {
        userReducer,
        deviceReducer,
    },
    middleware: [
        sagaMiddleware,
    ],
    devTools: true,
})

export type IState = ReturnType<typeof store.getState>
export type Dispatch = typeof store.dispatch
export default store