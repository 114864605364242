import {CSSProperties, useEffect, useRef, useState} from "react";
import * as echarts from "echarts";
import {merge} from "merge-anything";

const defaultOption = {
    title: {
        text: '',
        textStyle: {
            fontSize: '14px',
            textAlign: 'left',
            fontWeight: 600,
            height: 'auto',
        },
        left: '2px',
        top: '4px'
    },
    //提示
    tooltip: {
        trigger: 'axis',
    },
    //x轴
    xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
            //虚线
            lineStyle: {
                type: 'dashed'
            }
        },
    },
    yAxis: {
        type: 'value',
        axisLabel: {
            //y轴的数据的值旁边拼接单位
            formatter: "{value}℃",
        },
        max: 50,
        min: 10,
        splitLine: {
            //网格线
            lineStyle: {
                type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
            },
        },
        axisLine: {
            show: true,
            lineStyle: {
                type: 'dashed'
            }
        },
    },
    series: [
        {
            //折线类型
            type: 'line',
            data: [],
            markLine: {},
            areaStyle: {
                //渐变填充
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        //折线图初始填充背景
                        offset: 0,
                        color: 'rgba(65, 134, 244, 1)' // 0% 处的颜色
                    }, {
                        //折线图末尾背景
                        offset: 1,
                        color: '#fff' // 100% 处的颜色
                    }],
                }
            },
            //折点大小
            symbolSize: 8,
            //折线颜色
            itemStyle: {
                color: 'rgba(65, 134, 244, 1)',
            }
        },
    ],
}

interface BaseChartProps {
    id: string|number
    option?: any
    style?: CSSProperties
}

const BaseChart = ({option, id, style}: BaseChartProps) => {
    const frame = useRef<HTMLDivElement>(null)
    const [chart, setChart] = useState<echarts.ECharts|null>(null)

    useEffect(() => {
        let myChart = echarts.getInstanceByDom(document.querySelector(`.chart_${id}`) as HTMLElement)
        if (!myChart) {
            myChart = echarts.init(document.querySelector(`.chart_${id}`) as HTMLElement);
        }
        let opts = defaultOption
        if (option) {
            opts = merge(opts, option)
        }
        myChart.setOption(opts)
        setChart(myChart)
        return () => {
            myChart?.dispose()
        }
    }, [option])


    useEffect(() => {
        if (chart && frame && frame.current) {
            chart.resize({
                width: frame.current.clientWidth,
                height: frame.current.clientHeight,
            })
        }
    }, [chart, frame])

    return (
        <div ref={frame} className={`chart_${id}`} style={{...style}} />
    )
}

export default BaseChart