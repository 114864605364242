import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface Device {
    address: string
    exception: boolean
    name: string
    temps: Array<{
        temp: string
        time: number
    }>
    mac: string
    warn: {
        type: number,
        level: string
    }
    currentTemp: string
    patientCode: string
    start: boolean
    instanceId: string
    deptName: string,
    //添加电量（吕）
    battery:number
}

type Devices = Array<Device>

const initDeviceState: Array<Device> = []

export const DeviceSlice = createSlice({
    name: 'user',
    initialState: initDeviceState,
    reducers: {
        setDevice: (state: Devices, action: PayloadAction<Devices>): Devices => {
            return action.payload
        }
    }
})

export const { setDevice } = DeviceSlice.actions

export default DeviceSlice.reducer
