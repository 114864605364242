import './index.less'

interface lowType {
    name:string,
    address:string,
    battery:number
}

const LowBatteryCard = ({name,address,battery}:lowType)=>{
    return (
        <div className='low-battery-card-box'>
            <div>
                <div>{name}</div>
                <div>{address}</div>
            </div>
            <div>电量{battery}%，即将关机</div>
        </div>
    )
}

export default LowBatteryCard;

