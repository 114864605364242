import {Button, Input, Select, Space, Table} from "antd";
import { ColumnsType } from "antd/lib/table";
import {ChangeEvent, useCallback, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {listDepartment, listHistory} from "../../../api";
import {useSelector} from "react-redux";
import {IState} from "../../../redux";

interface DataType {
    id: string
    name: string
    patientCode: string
}

let timeoutId

const List = () => {
    const nav = useNavigate()
    const [data, setData] = useState<Array<DataType>>([])
    const [total, setTotal] = useState<number>(0)
    const [page, setPage] = useState<number>(1)
    const [size, setSize] = useState<number>(15)
    const [deptId,setDeptId] = useState<string|null>(null);
    const [keyword,setKeyword] = useState<string|null>(null);
    const [depts, setDepts] = useState<Array<any>>([])
    const isMaster = useSelector((state:IState) => state.userReducer.isMaster)

    const columns: ColumnsType<DataType> = [
        isMaster?{
            title:"科室",
            dataIndex:'deptName',
            align:'center',
        }:{
            width:'0'
        },
        {
            title: '姓名',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: "医疗卡号",
            dataIndex: "patientCode",
            align: "center",
        },
        {
            title: '操作',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => detail(record.id)}>详情</Button>
                </Space>
            ),
        },
    ];

    const detail = (id: string) => {
        nav(`/history/${id}`)
    }

    useEffect(() => {
        getData()
    }, [page, size,keyword,deptId])

    const getData = useCallback(() => {
        listHistory(page, size,keyword,deptId).then(
            res => {
                setData(res.data.data)
                setTotal(res.data.meta.total)
            }
        )
    }, [page, size,keyword,deptId])

    useEffect(()=>{
        listDepartment(1, 100).then(
            res => {
                setDepts(res.data.data)
            }
        )
    },[])

    const keywordChange = (e: ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timeoutId)
        let value = e.currentTarget.value
        timeoutId = setTimeout(() => {
            setKeyword(value)
        }, 500)
    }

    const deptIdChange = (value: any) => {
        setDeptId(value)
    }

    return (
       <>
           <div style={{display: "flex", margin: "10px 0 10px 0",justifyContent:"end"}}>
               <Input onChange={keywordChange} style={{width: "200px"}} placeholder="按医疗卡或姓名搜索" allowClear />&nbsp;&nbsp;
               {
                   isMaster ? (
                       <Select style={{width: "200px"}} placeholder="按科室搜索" allowClear onChange={deptIdChange}>
                           {
                               depts.map((item: any) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))
                           }
                       </Select>
                   ) : null
               }

           </div>
           <Table
               rowKey="id"
               columns={columns}
               dataSource={data}
               pagination={{
                   pageSize: size,
                   current: page,
                   total: total,
                   onChange: (page, size) => { setPage(page); setSize(size) },
               }}
           />
       </>
    )
}

export default List