import axios, { AxiosError } from "axios";
import {message} from "antd";
import Cookies from "js-cookie";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

const wsBase = ():string|void => {
    if (BASE_URL) {
        if (BASE_URL.indexOf("https://") !== -1) {
            return `wss://${BASE_URL.replace("https://", "")}`
        } else if (BASE_URL.indexOf("http://") !== -1) {
            return `ws://${BASE_URL.replace("http://", "")}`
        } else {
            console.warn("base url is invalid")
        }
    }
}

export const MY_URL = wsBase();

const instance = axios.create({
    baseURL: BASE_URL
});

const loginPath = process.env.PUBLIC_URL+'/login'

instance.interceptors.request.use((config) => {
    let token = Cookies.get('token');
    //判断除login外是否存在token
    if (!token && config.url !== '/login') {
        window.location.href=loginPath
        message.error('未登录');
        return config
    }
    //添加请求头
    if (token && config.headers) {
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
})

instance.interceptors.response.use(
    response => {
        if (response?.data?.code >= 400) {
            return Promise.reject(response)
        }
        return Promise.resolve(response)
    },
    err => {
        if (err.response?.status >= 400) {
            switch (err.response.status) {
                case 401:
                    Cookies.remove('token')
                    window.location.href=loginPath
                    message.error("请登录")
                    return
                default:
                    return Promise.reject(err.response)
            }
        }
        return Promise.reject(err.response)
    }
)

export default instance