import { Button, Dropdown, Layout as AntdLayout, Space, Menu as AntdMenu } from 'antd'
import { useCallback, useEffect } from "react"
import Menu from "../menu"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { DownOutlined } from "@ant-design/icons"
import './index.less'
import { self } from '../../api'
import { setUser } from '../../redux/reducers/user'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { IState } from '../../redux'
import Active from '../active'


const Layout = () => {
    const loc = useLocation()
    const dispatch = useDispatch()
    const username = useSelector((state: IState) => state.userReducer.username)
    const deptName = useSelector((state: IState) => state.userReducer.deptName)
    const nav = useNavigate()

    const logout = useCallback(() => {
        Cookies.remove('token')
        nav("/login")
    }, [])

    const menu = (
        <AntdMenu
            items={[
                {
                    label: (
                        <Button type='link' onClick={logout}>登出</Button>
                    ),
                    key: 'logout'
                }
            ]}
        />
    )

    useEffect(() => {
        self().then(
            res => {
                dispatch(setUser({
                    id: res.data.data.id,
                    username: res.data.data.username,
                    deptName: res.data.data.deptName,
                    deptId: res.data.data.depId,
                    isMaster: res.data.data.isMaster,
                }))
            }
        )
    }, [])

    return (
        <AntdLayout id="layout">
            <AntdLayout.Header className="site-layout-header">
                <div><h1 style={{display:"inline-block"}}>医用动态体温监控系统</h1>&nbsp;<span style={{color: "#1890ff"}}>{deptName}</span>&nbsp;<Active /></div>
                <Dropdown overlay={menu} trigger={['click']}>
                    <a onClick={e => e.preventDefault()}>
                        <Space>
                            {username}
                            <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
            </AntdLayout.Header>
            <AntdLayout className="site-layout">
                <AntdLayout.Sider width={200} className="sider">
                    <Menu current={loc.pathname ? loc.pathname.replace("/", '') : 'home'} />
                </AntdLayout.Sider>
                <AntdLayout.Content
                    style={{
                        padding: 24,
                        height: "100%",
                    }}
                >
                    <Outlet />
                    <div style={{position: 'fixed', bottom: '20px', left: "45%", textAlign: "center"}}>技术支持：四川汉尔医疗科技有限公司</div>
                </AntdLayout.Content>
            </AntdLayout>
        </AntdLayout>
    )
}

export default Layout
