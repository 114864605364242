import './index.less'
import { Button, Form, Input, message, Modal, Select, Table } from "antd"
import { useCallback, useEffect, useState } from "react"
import Checkbox from 'antd/es/checkbox'
import { listAccount, updateAccount, createAccount, delAccount, listDepartment, resetAccountPassword } from "../../api"
import moment from "moment"
import { AxiosPromise } from 'axios'
import PwdTip from '../../components/pwd-tip'

export interface fromAddAccountsType {
    //账号
    username?: string,
    //权限
    permissions?: number[] | string[],
    //科室
    departmentCode?: string,
}



//账户管理
const Account = () => {

    //表body Data
    const [data, setData] = useState<any[]>()

    //科室Data
    const [departments, setDepartments] = useState<any[]>([])

    //添加弹窗
    const [showEditor, setShowEditor] = useState<boolean>(false)

    //当前页数
    const [page, setPage] = useState<number>(1)

    const [size, setSize] = useState<number>(15)

    const [total, setTotal] = useState<number>(0)

    //当前id
    const [id, setId] = useState<string>('')

    const [form] = Form.useForm()

    const [initPassword, setInitPassword] = useState<string>('')

    //账号列表api
    const getData = useCallback(() => {
        listAccount(page, size).then(
            r => {
                setData(r.data.data)
                setTotal(r.data.meta.total)
            }
        );
    }, [page, size])

    //添加和编辑弹窗确定btn
    const submit = () => {
        form.validateFields().then(
            values => {
                let request: AxiosPromise
                if (id) {
                    request = updateAccount(id, values)
                } else {
                    request = createAccount(values)
                }
                request.then(
                    res => {
                        if (!id) {
                            setInitPassword(res.data.data.password)
                        }
                        getData()
                        setId('')
                        setShowEditor(false)
                        form.resetFields()
                    }
                )
            }
        )
    }

    const edit = (record: any) => {
        setId(record.id)
        form.setFieldsValue(record)
        setShowEditor(true)
    }

    const del = (id: string, username: string) => {
        Modal.warning({
            title: `确定删除账号<${username}>吗？`,
            okText: '确定',
            closable: true,
            onOk: () => {
                delAccount(id).then(
                    () => {
                        getData()
                        message.success('删除成功')
                    }
                )
            }
        })
    }

    //表头
    const columns: any[] = [
        {
            title: '账户',
            dataIndex: 'username',
            width: '25%',
            align: 'center',
        },
        {
            title: '科室',
            dataIndex: 'depId',
            width: '25%',
            align: 'center',
            render: (depId: string) => departments.find(item => item.id === depId)?.name
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: '25%',
            align: 'center',
            render: (text: number) => {
                return (
                    moment(text * 1000).format('YYYY-MM-DD HH:mm:ss')
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'actions',
            width: '25%',
            align: 'center',
            render: (_, record: any) => {
                return (
                    <>
                        <Button type='primary' onClick={() => edit(record)}>修改</Button>&nbsp;
                        <Button type='primary' onClick={() => resetPassword(record.id)}>重置密码</Button>&nbsp;
                        <Button type='primary' danger onClick={() => del(record.id, record.username)}>删除</Button>
                    </>
                )
            }
        }
    ]

    const resetPassword = (id: string) => {
        Modal.warning({
            title: `确定重置密码吗？`,
            okText: '确定',
            closable: true,
            onOk: () => {
                resetAccountPassword(id).then(
                    res => {
                        setInitPassword(res.data.data.password)
                    }
                )
            }
        })
    }

    useEffect(() => {
        listDepartment(1, 200).then(
            r => {
                setDepartments(r.data.data)
            }
        )
    }, [])

    useEffect(() => {
        getData()
    }, [page])

    return (
        <>
            <Button type="primary" onClick={() => setShowEditor(true)}>创建</Button>
            <Table
                columns={columns}
                dataSource={data}
                rowKey="id"
                bordered={false}
                pagination={{
                    current: page,
                    pageSize: size,
                    total: total,
                    onChange: (page: number, pageSize: number) => setPage(page)
                }}
            />
            <Modal
                visible={showEditor}
                onOk={submit}
                onCancel={() => { setShowEditor(false); setId(''); form.resetFields() }}
                title="账号"
            >
                <Form form={form}>
                    <Form.Item
                        label="用户名"
                        name="username"
                        rules={[{ required: true, message: "用户名不能为空" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="科室"
                        name="depId"
                        rules={[{ required: true, message: "科室不能为空" }]}
                    >
                        <Select>
                            {
                                departments.map(item => (
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                ))
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="权限"
                        name="permissions"
                    >
                        <Checkbox.Group>
                            <Checkbox value={1} key="account">账号管理</Checkbox>
                            <Checkbox value={2} key="system">系统设置</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </Modal>
            <PwdTip password={initPassword} />
        </>
    )
}

export default Account;