import './index.less'
import { Button, Form, Input, message } from "antd";
import { login } from "../../api";
import Cookies from 'js-cookie'
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Login = () => {
    const nav = useNavigate()

    useEffect(() => {
        if (Cookies.get('token')) {
            nav('/monitor')
        }
    }, [])

    //登录提交
    const onFinish = (v: any) => {
        login({
            username: v.name,
            password: v.passwd
        }).then((r: any) => {
            if (r?.data?.code === 200) {
                Cookies.set('token', r.data.data.token)
                nav('/monitor')
                message.success(`${v.name}账号登录成功`)
                return
            }
        },(err:any)=>{
            message.error(err.data.msg)
        });
    }

    return (
        <div className='login_box'>
            <div className='login_inner'>
                <h2>欢迎登录汉尔医用动态体温监控系统</h2>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label='账号'
                        name="name"
                        rules={[{ required: true, message: '请输入您的账号' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='密码'
                        name="passwd"
                        rules={[{ required: true, message: '请输入您的密码' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Login;