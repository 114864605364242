import { Button, DatePicker, Form, Modal, Table } from "antd"
import { useForm } from "antd/es/form/Form"
import { ColumnsType } from "antd/lib/table"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { documentByHistoryId, fetchTemps, listMaximumTemp } from "../../api"
import BaseChart from "../../components/chart/base"
import "./index.less"
import List from "./list"
import {BASE_URL} from '../../api/axios'
import Cookies from "js-cookie"
import { RangePickerProps } from "antd/lib/date-picker"

interface IParams {
    id: string
}

interface DataType {
    time: string
    max: string
    min: string
}

interface FormItems {
    time_range: Array<moment.Moment>
}

const History = () => {
    const [title, setTitle] = useState("")
    const [doc, setDoc] = useState<any>(null)
    const [data, setData] = useState<Array<any>>([])
    const params = useParams<Record<keyof IParams, string>>()
    const [maximum, setMaximum] = useState<Array<any>>([])
    const [maximumPage, setMaximumPage] = useState<number>(1)
    const [maximumSize, setMaximumSize] = useState<number>(15)
    const [maximumTotal, setMaximumTotal] = useState<number>(0)
    const [form] = useForm<FormItems>()
    const [showExportModal, setShowExportModal] = useState(false)
    const [start, setStart] = useState<number>(moment().subtract(7, "days").startOf("day").unix())
    const [end, setEnd] = useState<number>(moment().endOf("day").unix())

    const opts = useMemo(() => {
        let opts: any = {
            title: {
                text: title,
            },
            grid: {
                // top: '20%',
                // bottom: '15%',
                // // 网格高度
                // // height: '50%'
                left: 40,
                right: 10,
            },
            xAxis: {
                type: 'category',
                data: data.map(item => moment(item.time*1000).format("HH:mm:ss"))
            },
            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100
                },
                {
                    start: 0,
                    end: 100
                },
                {
                    // 滑动轴背景
                    // backgroundColor: 'rgba(240, 240, 240, 1)',
                    // //滑动轴边框
                    // borderColor: 'rgba(240, 240, 240, 1)',
                    // 左右icon样式
                    // handleStyle: {
                    //     color: myOptions.lineColor,
                    // },
                    // 上方拖动事件
                    // moveHandleStyle: {
                    //     opacity: 0,
                    //     color:'red'
                    // },
                    // 矩形选择区
                    // brushStyle: {
                    //     color: myOptions.lineColor
                    // },
                    //
                    // emphasis: {
                    //     //左右拖动时的样式
                    //     handleStyle: {
                    //         color: 'red'
                    //     },
                    // },
                    //
                    // dataBackground: {
                    //     lineStyle: {
                    //         color: "red"
                    //     }
                    // },
                }
            ],
            yAxis: {
                max:45,
                min:30
                // show: false
            },
            series: [
                {
                    //吕（添加曲线平滑）
                    smooth: true,
                    //折线类型
                    type: 'line',
                    data: data.map(item => item.temp),
                    markLine: {},
                    label: {
                        show: true,
                        position: 'top',
                        formatter: '{@y}°C'
                    },
                    areaStyle: {
                        //渐变填充
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                //折线图初始填充背景
                                offset: 0,
                                color: 'rgba(65, 134, 244, 1)' // 0% 处的颜色
                            }, {
                                //折线图末尾背景
                                offset: 1,
                                color: '#fff' // 100% 处的颜色
                            }],
                        }
                    },
                    //折点大小
                    symbolSize: 8,
                    //折线颜色
                    itemStyle: {
                        color: 'rgba(65, 134, 244, 1)',
                    },
                },
            ],
            toolbox: {
                // feature: {
                //     dataZoom: {
                //         yAxisIndex: 'none',
                //         title: {
                //             zoom: '区域缩放',
                //             back: '区域还原'
                //         }
                //     },
                //     restore: {
                //         title: '视图还原'
                //     },
                //     saveAsImage: {
                //         title: '保存图片'
                //     },
                // },
                brush: {
                    xAxisIndex: 'all',
                    brushLink: 'all',
                    outOfBrush: {
                        colorAlpha: 0.1
                    }
                },
            }
        }
        return opts
    }, [data])

    const cols: ColumnsType<DataType> = [
        {
            title: "时间",
            dataIndex: "time",
            align: "center",
            width: 300,
            render: (time: string) => {
                let t = moment(time, "YYYY-MM-DD HH").add(8, 'hour')
                return `${t.format("YYYY-MM-DD HH:mm:ss")}~${t.add(1, 'hour').format("HH:mm:ss")}`
            }
        },
        {
            title: '最大值',
            dataIndex: 'max',
            align: 'center',
        },
        {
            title: '最小值',
            dataIndex: 'min',
            align: 'center'
        }
    ]

    const getMaximum = useCallback(() => {
        if (params.id) {
            listMaximumTemp(params.id, start, end).then(
                res => {
                    let data:any = res.data.data;
                    data = data.map(item=>{
                        return {
                            ...item,
                            max:item.max+"℃",
                            min:item.min+"℃",
                        }
                    })
                    setMaximum(data)
                    setMaximumTotal(res.data.meta.total)
                }
            )
        }
    }, [params.id, start, end, maximumPage, maximumSize])

    const ex = useCallback(() => {
        if (params.id) {
            form.validateFields().then(
                (values: FormItems) => {
                    window.open(
                        `${BASE_URL}history/${params.id}/export?filters[start]=${values.time_range[0].unix()}&filters[end]=${values.time_range[1].unix()}&token=${Cookies.get('token')}`,
                        "_blank"
                    )
                    form.resetFields()
                    setShowExportModal(false)
                }
            )
        }
    }, [params.id])

    const rangeChange = useCallback((value: RangePickerProps['value']) => {
        if (value?.[0] && value?.[1]) {
            let v1 = value[0].unix()
            let v2 = value[1].unix()
            if (v1 >= v2) {
                setStart(v2)
                setEnd(v1)
            } else {
                setStart(v1)
                setEnd(v2)
            }
        }
    }, [setStart, setEnd])

    useEffect(() => {
        if (params.id) {
            documentByHistoryId(params.id).then(
                res => {
                    setDoc(res.data.data)
                }
            )
            fetchTemps(params.id, start, end).then(
                res => {
                    setData(res.data.data)
                }
            )
            getMaximum()
        }
    }, [params, start, end])

    return (
        <>
            <div className="history">
                <span className="title">病例详情</span>
                <div className="info">
                    <div style={{marginRight: "50px", flex: "0 0 auto",margin:'0 auto'}}>
                        <span style={{fontSize: "30px",marginRight:'50px'}}>{doc?.name}</span>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-around", height: "100%", flex: "0 0 auto"}}>
                        <span>医疗卡号: {doc?.patientCode}</span>
                        <span>患者床号: {doc?.address}</span>
                        <span>医疗费用: {doc?.totalFee}元</span>
                        <span>监测时间：{moment.utc(doc?.totalTime*1000).format('HH时mm分ss秒')}</span>
                        {/* <span>设备编号: 111111111</span> */}
                    </div>
                    <div style={{width:"100%"}}>
                        <Button type="primary" style={{float: "right"}} onClick={() => setShowExportModal(true)}>导出数据</Button>
                    </div>
                </div>
                <span className="title">温度数据</span>
                <div className="temp">
                    <div className="chart">
                        <DatePicker.RangePicker allowClear={false} onCalendarChange={rangeChange} value={[moment(start*1000), moment(end*1000)]} />
                        <BaseChart option={opts} id={params.id || ''} style={{width: "100%", height: '300px'}} />
                    </div>

                    <span className="title">每小时最值</span>
                    <div className="hour-info">
                        <Table 
                            columns={cols} 
                            dataSource={maximum}
                            rowKey="time"
                            pagination={{
                                pageSize: maximumSize,
                                current: maximumPage,
                                total: maximumTotal,
                                onChange: (page, size) => { setMaximumPage(page); setMaximumSize(size) },
                            }}
                        ></Table>
                    </div>
                </div>
            </div>
            <Modal title="导出" visible={showExportModal} onOk={ex} onCancel={() => setShowExportModal(false)}>
                <Form form={form}>
                    <Form.Item 
                        name="time_range"
                        rules={[{required: true, message: '请选择时间段'}]}
                    >
                        <DatePicker.RangePicker />
                    </Form.Item>
                </Form>
            </Modal>
        </>
        
    )
}

export {
    List as HistoryList,
    History as HistoryDetail
}