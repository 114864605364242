import { Button, DatePicker, Form, Input, InputNumber, message, Modal, Space, Tag, TimePicker } from "antd"
import { useForm } from "antd/lib/form/Form"
import Table, { ColumnsType } from "antd/lib/table"
import { AxiosPromise } from "axios"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { createCareLevel, deleteCareLevel, listCareLevel, updateCareLevel } from "../../../api"

interface DataType {
    id: string
    name: string
    startTime: string
    duration: number
    s?: moment.Moment
}

const CareLevel = () => {
    const [data, setData] = useState<Array<DataType>>([])
    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [showEditor, setShowEditor] = useState<boolean>(false)
    const [form] = useForm()
    const [currentId, setCurrentId] = useState<string>("")
    const [size, setSize] = useState<number>(15)

    const columns: ColumnsType<DataType> = [
        {
            title: '级别',
            dataIndex: 'name',
        },
        {
            title: '每天开始时间',
            dataIndex: 'startTime',
        },
        {
            title: 'min',
            dataIndex: 'duration',
            render: (duration: number) => {
                return duration ? `${duration}小时` : ""
            }
        },
        {
            title: '操作',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => edit(record)}>编辑</Button>
                    <Button type="primary" danger onClick={() => del(record.id)}>删除</Button>
                </Space>
            ),
        },
    ];

    const edit = (record: DataType) => {
        setCurrentId(record.id)
        record.s = moment(record.startTime, "HH:mm:ss")
        form.setFieldsValue(record)
        setShowEditor(true)
    }

    const submit = () => {
        form.validateFields().then(
            values => {
                values.startTime = values.s.format("HH:mm:ss")
                let request: AxiosPromise
                if (currentId) {
                    request = updateCareLevel(currentId, values)
                } else {
                    request = createCareLevel(values)
                }
                request.then(
                    () => {
                        getData()
                        message.success("保存成功")
                        setCurrentId("")
                        setShowEditor(false)
                        form.resetFields()
                    }
                )
            }
        )
    }

    const del = (id: string) => {
        deleteCareLevel(id).then(
            () => {
                if (data.length < 2 && page >= 2) {
                    setPage(page - 1)
                } else {
                    getData()
                }
                message.success("删除成功")
            }
        )
    }

    const getData = useCallback(() => {
        listCareLevel(page, size).then(
            res => {
                setData(res.data.data)
                setTotal(res.data.meta.total)
            }
        )
    }, [page])

    useEffect(() => {
        getData()
    }, [page])

    return (
        <>
            <Button type="primary" onClick={() => setShowEditor(true)}>创建</Button>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: size,
                    current: page,
                    total: total,
                    onChange: (page, size) => { setPage(page); setSize(size) },
                }}
            />
            <Modal title="护理级别" visible={showEditor} onOk={submit} onCancel={() => setShowEditor(false)}>
                <Form form={form}>
                    <Form.Item
                        label="护理级别"
                        name="name"
                        rules={[{ required: true, message: "级别不能为空" }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="每天开始时间"
                        name="s"
                    >
                        <DatePicker picker="time" />
                    </Form.Item>
                    <Form.Item
                        label="时间间隔（h）"
                        name="duration"
                    >
                        <InputNumber min={1} max={24}/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CareLevel

interface UploadPointsIProps {
    value?: Array<string>
    onChange?: (list: Array<string>) => void
}

const UploadPoints = ({ value = [], onChange }: UploadPointsIProps) => {
    const del = (index: number) => {
        if (onChange && value) {
            onChange(value.filter((item, i) => i != index))
        }
    }
    const add = (time: moment.Moment) => {
        if (onChange && value) {
            let set = new Set(value)
            set.add(time.format("HH:mm:ss"))
            onChange(Array.from(set))
        }
    }

    useEffect(() => {
        if (onChange && value) {
            onChange(value)
        }
    }, [])

    return (
        <>
            <div style={{ display: "inline-block" }}>
                {
                    value?.map((item, index) => (
                        <Tag key={index} closable={true} onClose={(e) => { e.preventDefault(); del(index) }}>{item}</Tag>
                    ))
                }
            </div>
            <TimePicker
                placeholder="添加时间点"
                onOk={add}
                inputReadOnly={true}
                showNow={false}
            />
        </>
    )
}