import { Input, message, Modal } from "antd"
import moment from "moment"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { actived, register } from "../../api"

const Active = () => {
    const [isActived, setIsActived] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [code, setCode] = useState("")
    const [start, setStart] = useState(0)
    const [end, setEnd] = useState(0)
    
    const activeText = useMemo(() => {
        if (!isActived) {
            return (<span style={{color:"red"}}>(未激活)</span>)
        }
        return ""
    }, [isActived])

    const submit = useCallback(() => {
        if (code) {
            register(code).then(
                res => {
                    message.success("激活成功")
                    setCode("")
                    setShowModal(false)
                    getActiveInfo()
                },
                () => {
                    message.error("激活失败")
                    setCode("")
                    setShowModal(false)
                }
            )
            return
        }
        message.error("激活失败")
        setCode("")
        setShowModal(false)
    }, [code])

    const getActiveInfo = () => {
        actived().then(
            res => {
                setIsActived(res.data.data.actived)
                setStart(res.data.data.start)
                setEnd(res.data.data.end)
            },
            () => {
                setIsActived(false)
            }
        )
    }

    useEffect(() => {
        getActiveInfo()
    }, [])

    return (
        <>
            {isActived ? (<span onClick={() => setShowModal(true)}>({moment(start*1000).format("YYYY/MM/DD")}~{moment(end*1000).format("YYYY/MM/DD")})</span>) : (<span onClick={() => setShowModal(true)} style={{color:"red", cursor: "pointer"}}>(未激活)</span>)}
            <Modal title="请输入激活码" visible={showModal} onOk={submit} onCancel={() => {setCode("");setShowModal(false)}}>
                <p>
                    <Input.TextArea autoSize value={code} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {setCode(e.currentTarget.value)}} />
                </p>
            </Modal>
        </>
        
    )
}

export default Active