import './App.less'
import { Routes, Route } from 'react-router-dom'
import Login from './pages/login'
import Layout from './components/layout'
import CareLevel from './pages/setting/care-level'
import Setting from './pages/setting/setting'
import Account from './pages/account'
import Department from './pages/setting/department'
import Document from './pages/document'
import RealTime from './pages/real-time'
import {HistoryList, HistoryDetail} from './pages/history'

function App() {
    return (
        <Routes>
            <Route path="login" element={<Login />} />
            <Route path="/" element={<Layout />}>
                <Route path="document" element={<Document />} />
                <Route path="account" element={<Account />} />
                <Route path="setting" element={<Setting />} />
                <Route path="care-level" element={<CareLevel />} />
                <Route path="department" element={<Department />} />
                <Route path="monitor" element={<RealTime />} />
                <Route path="history" element={<HistoryList />} />
                <Route path="history/:id" element={<HistoryDetail />} />
            </Route>
        </Routes>
    );
}

export default App;
