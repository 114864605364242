import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import Card from "../../components/card"
import WarnCard from "../../components/card/warn-card"
import { IState, sagaMiddleware } from "../../redux"
import { DeviceSaga } from "../../saga"
import LowBatteryCard from "../../components/card/low-battery-card";
import { CaretDownOutlined,CaretUpOutlined } from "@ant-design/icons"

interface hide {
    mac: string
    level: string
    time: number
}

interface styleType{
    height:string,
    overflow:string
}

const RealTime = () => {
    const devices = useSelector((state: IState) => state.deviceReducer)

    const [lowStyle,setLowStyle] = useState<styleType>({
        height:'102px',
        overflow:'hidden'
    });

    const [lowIsStyle,setLowIsStyle] = useState<boolean>(false);

    const [warnStyle,setWarnStyle] = useState<styleType>({
        height:'112px',
        overflow:'hidden'
    });

    const [warnIsStyle,setWarnIsStyle] = useState<boolean>(false);

    useEffect(() => {
        let task = sagaMiddleware.run(DeviceSaga)

        return () => {
            task.cancel()
        }
    }, [])

    const warnDevices = useMemo(() => {
        return devices.filter(item => item.warn.type !== 0)
    }, [devices]);

    const lowDevices = useMemo(()=>devices.filter(item=>Number(item.battery)<=10),[devices])

    return (
        <>
            {
                lowDevices?.length>0?(
                    <div style={{position:"relative"}}>
                        <div style={{marginBottom:'20px',fontSize:'30px'}}>低电量设备</div>
                        <div style={{
                            width: "99%",
                            backgroundColor: "white",
                            marginBottom:"10px",
                            marginRight: "-10px",
                            boxSizing: "border-box",
                            overflow: lowStyle.overflow,
                            height: lowStyle.height,
                            padding: "0 10px 10px 10px",
                            borderRadius: "10px",
                            display: "flex",
                            flexWrap: "wrap",
                            boxShadow:"2px 2px 2px rgba(0,0,0,.2)"
                        }}>
                            {
                                lowDevices.map(item=>(
                                    <LowBatteryCard
                                        name={item.name}//添加名字（吕）
                                        address={item.address}//添加床号（吕）
                                        battery={item.battery}//添加电量（吕）
                                    />
                                ))
                            }
                        </div>
                        <div style={{cursor:"pointer", fontSize:'30px',position:'absolute',left:'50%',transform:'translateX(-50%)',bottom:"-32px"}}>
                            {
                                lowIsStyle?<CaretUpOutlined onClick={()=>{
                                    setLowStyle({
                                        height:"102px",
                                        overflow:'hidden'
                                    });
                                    setLowIsStyle(false)
                                }
                                }/>:<CaretDownOutlined onClick={()=>{
                                    setLowStyle({
                                        height:"auto",
                                        overflow:'auto'
                                    });
                                    setLowIsStyle(true)
                                }
                                }/>
                            }
                        </div>
                    </div>
                ):null
            }
            {
                warnDevices?.length>0?(
                    <div style={{position:"relative"}}>
                    <div style={{marginBottom:'20px',fontSize:'30px'}}>报警设备</div>
                        <div style={{
                            width: "99%",
                            backgroundColor: "white",
                            marginBottom:"10px",
                            marginRight: "-10px",
                            boxSizing: "border-box",
                            padding: "0 10px 10px 10px",
                            borderRadius: "10px",
                            display: "flex",
                            flexWrap: "wrap",
                            overflow: warnStyle.overflow,
                            height: warnStyle.height,
                            boxShadow:"2px 2px 2px rgba(0,0,0,.2)"
                        }}>
                            {
                                warnDevices.map(item => (
                                    <WarnCard
                                        key={item.mac}
                                        name={item.name}
                                        style={{marginRight: "10px"}}
                                        address={item.address}
                                        type={item.warn.type}
                                        level={item.warn.level}
                                        mac={item.mac}
                                    />
                                ))
                            }
                        </div>
                        <div style={{cursor:"pointer", fontSize:'30px',position:'absolute',left:'50%',transform:'translateX(-50%)',bottom:"-32px"}}>
                            {
                                warnIsStyle?<CaretUpOutlined onClick={()=>{
                                    setWarnStyle({
                                        height:"112px",
                                        overflow:'hidden'
                                    });
                                    setWarnIsStyle(false)
                                }
                                }/>:<CaretDownOutlined onClick={()=>{
                                    setWarnStyle({
                                        height:"auto",
                                        overflow:'auto'
                                    });
                                    setWarnIsStyle(true)
                                }
                                }/>
                            }
                        </div>
                    </div>
                ):null
            }
            <div style={{
                display: "flex",
                flexWrap: "wrap",
                height: '100%',
                marginRight: '-10px', //抵消每行最后一个元素的margin-right
                marginBottom: '-10px',
                alignContent: 'flex-start'
            }}>
                {
                    devices.map(item => (
                        <Card
                            id={item.mac}
                            key={item.mac}
                            address={item.address}
                            name={item.name}
                            currentTemp={item.currentTemp}
                            startTime={30}
                            temps={item.temps}
                            warn={item.warn}
                            patientCode={item.patientCode}
                            start={item.start}
                            mac={item.mac}
                            instanceId={item.instanceId}
                            deptName={item.deptName}
                            battery={item.battery}//添加电量（吕）
                            style={{ flex: "0 0 auto", marginRight: "10px", marginBottom: "10px" }}
                        />
                    ))
                }
            </div>
        </>
    )
}

export default RealTime