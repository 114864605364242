import { call, delay, fork, put } from "redux-saga/effects"
import { listDevice } from "../api"
import { setDevice } from "../redux/reducers/device"

function* printLog() {
    while (true) {
        yield delay(2000)
        const response = yield call(listDevice)
        yield put(setDevice(response.data.data))
    }
}

function* DeviceSaga() {
    yield fork(printLog)
}

export default DeviceSaga