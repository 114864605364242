import { Button, Form, Input, message, Modal, Space } from "antd"
import { useForm } from "antd/lib/form/Form"
import Table, { ColumnsType } from "antd/lib/table"
import { AxiosPromise } from "axios"
import { useCallback, useEffect, useState } from "react"
import { createDepartment, deleteDepartment, listDepartment, updateDepartment } from "../../../api"

interface DataType {
    id: string
    name: string
    isMaster: boolean
}

const Department = () => {
    const [data, setData] = useState<Array<DataType>>([])
    const [page, setPage] = useState<number>(1)
    const [total, setTotal] = useState<number>(0)
    const [showEditor, setShowEditor] = useState<boolean>(false)
    const [form] = useForm()
    const [currentId, setCurrentId] = useState<string>("")
    const [size, setSize] = useState<number>(15)

    const columns: ColumnsType<DataType> = [
        {
            title: '名称',
            dataIndex: 'name',
            align: 'center',
        },
        {
            title: '操作',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Button onClick={() => edit(record)}>编辑</Button>
                    <Button type="primary" danger onClick={() => del(record)}>删除</Button>
                </Space>
            ),
        },
    ];

    const edit = (record: DataType) => {
        setCurrentId(record.id)
        form.setFieldsValue(record)
        setShowEditor(true)
    }

    const submit = () => {
        form.validateFields().then(
            values => {
                let request: AxiosPromise
                if (currentId) {
                    request = updateDepartment(currentId, values)
                } else {
                    request = createDepartment(values)
                }
                request.then(
                    () => {
                        getData()
                        message.success("保存成功")
                        setCurrentId("")
                        setShowEditor(false)
                        form.resetFields()
                    }
                )
            }
        )
    }

    const del = (record: DataType) => {
        if (record.isMaster) {
            message.warn("系统内置科室无法删除")
            return
        }
        deleteDepartment(record.id).then(
            () => {
                if (data.length < 2 && page >= 2) {
                    setPage(page - 1)
                } else {
                    getData()
                }
                message.success("删除成功")
            },
            (err:any)=>{
                message.error(err.data.msg)
            }
        )
    }

    const getData = useCallback(() => {
        listDepartment(page, size).then(
            res => {
                setData(res.data.data)
                setTotal(res.data.meta.total)
            }
        )
    }, [page])

    useEffect(() => {
        getData()
    }, [page])

    return (
        <>
            <Button type="primary" onClick={() => setShowEditor(true)}>创建</Button>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={data}
                pagination={{
                    pageSize: size,
                    current: page,
                    total: total,
                    onChange: (page, size) => { setPage(page); setSize(size) },
                }}
            />
            <Modal title="科室" visible={showEditor} onOk={submit} onCancel={() => setShowEditor(false)}>
                <Form form={form}>
                    <Form.Item
                        label="名称"
                        name="name"
                        rules={[{ required: true, message: "名称不能为空" }]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default Department
